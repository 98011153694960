import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import LinearProgress from "@material-ui/core/LinearProgress";

const GetinByEmailOTPScreen = lazy(() => import("./essential/GetinByEmailOTPScreen"));
const GetInByPhone = lazy(() => import("./essential/GetInByPhone"));
const GetinByPhoneOTPScreen = lazy(() => import("./essential/GetinByPhoneOTPScreen"));
const UserRegistration = lazy(() => import("./essential/UserRegistartion"));
const TheLayout = lazy(() => import("./essential/TheLayout"));
const LandingPage = lazy(() => import("./essential/LandingPageOld"));


function App() {

  return (
    <div className="App">
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <Route
            exact
            path="/loginByEmailOTPScreen/:number"
            name="Login By Email"
            component={GetinByEmailOTPScreen}
          />
          <Route
            exact
            path="/loginByPhone"
            name="Login By Phone"
            component={GetInByPhone}
          />
          <Route
            exact
            path="/loginByPhoneOTPScreen/:number"
            name="Login By Phone OTP Screen"
            component={GetinByPhoneOTPScreen}
          />

          <Route
            exact
            path="/LandingPage"
            name="Landing Page"
            component={LandingPage}
          />

          <Route
            exact
            path="/UserRegistration/:number"
            name="Registration page"
            component={UserRegistration}
          />
          <Route path="/" name="Home" component={TheLayout} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
